import React from 'react';
import QRCode from 'react-qr-code';
import { useParams, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { CTooltip } from '@coreui/react';
import { User } from '../../models/user-model';
import './blockchain-address-component.scss';
import { Contract } from '../../models/contract-model';
import ShortTextControl from '../../controls/short-text/short-text-control';
import useMediaQuery from '../../hooks/use-media-query';
import MediaQuery from '../../models/media-query';

type Params = {
  selectedAddress: string;
};

type DropDownToggleProps = {
  address: string;
  showMenu: boolean;
  blockchain?: string;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

type WalletElementProps = {
  address: string;
  blockchain: string;
};

function WalletElement({ address, blockchain }: WalletElementProps): JSX.Element {
  const maxXs = useMediaQuery(MediaQuery.maxXs);
  const maxXl = useMediaQuery(MediaQuery.maxXl);

  return (
    <div className="wallet-element">
      {maxXs ? (
        <div className="align-items-center">
          <div>
            <i className="blockchain-image" id={blockchain} />
          </div>
          <div>
            <ShortTextControl text={address} fullDisplay={maxXl} maxAdaptiveWidthPercentage={90} hideCopyButton />
          </div>
        </div>
      ) : (
        <div className="align-items-center">
          <div>
            <QRCode size={60} level="Q" value={address} className="qr-code" />
          </div>
          <div>
            <i className="blockchain-image" id={blockchain} />
          </div>
          <div>
            <ShortTextControl text={address} fullDisplay={maxXl} maxAdaptiveWidthPercentage={90} hideCopyButton />
          </div>
        </div>
      )}
    </div>
  );
}

const DropDownToggle = React.forwardRef((props: DropDownToggleProps, ref: React.Ref<HTMLDivElement>) => {
  const maxXs = useMediaQuery(MediaQuery.maxXs);
  const maxXl = useMediaQuery(MediaQuery.maxXl);

  return (
    <div
      onKeyUp={() => {}}
      role="button"
      tabIndex={0}
      className="wallet-dropdown-button text-white "
      ref={ref}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (props.onClick) {
          props.onClick(e);
        }
      }}
    >
      {maxXs ? (
        <div className="align-items-center">
          <div>
            <i className="blockchain-image" id={props.blockchain} />
          </div>
          <div
            onKeyUp={(e) => {
              e.stopPropagation();
            }}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <CTooltip
              trigger="click"
              content={<QRCode size={70} level="Q" value={props.address} className="qr-code" />}
              placement="auto"
            >
              <i className="icon-qrcode" />
            </CTooltip>
          </div>
          <div>
            <ShortTextControl
              text={props.address}
              maxAdaptiveWidthPercentage={80}
              theme="Light"
              tooltipPlacement="top"
              fullDisplay={maxXl}
            />
          </div>
          <div>
            <span>&#x25bc;</span>
          </div>
        </div>
      ) : (
        <div className="align-items-center">
          <div>
            <QRCode size={70} level="Q" value={props.address} className="qr-code" />
          </div>
          <div>
            <i className="blockchain-image" id={props.blockchain} />
          </div>
          <div>
            <ShortTextControl
              text={props.address}
              maxAdaptiveWidthPercentage={80}
              theme="Light"
              tooltipPlacement="top"
              fullDisplay={maxXl}
            />
          </div>
          {props.showMenu && (
            <div>
              <span>&#x25bc;</span>
            </div>
          )}
        </div>
      )}
      {props.children}
    </div>
  );
});

export default function BlockchainAddressComponent(props: { user: User }): JSX.Element {
  const { user } = props;
  const { selectedAddress } = useParams<keyof Params>() as Params;
  const navigate = useNavigate();
  const blockchainSortOrder: { [name: string]: number } = {
    counterparty: 0,
    ethereum: 1,
    firstOasisKlaytn: 2,
  };

  const compare = (a: Contract, b: Contract): number => {
    return blockchainSortOrder[a.blockchain] > blockchainSortOrder[b.blockchain] ? 1 : -1;
  };

  const items = React.useMemo(
    () => user.data?.addresses?.filter((f) => f.address.toLowerCase() !== selectedAddress.toLowerCase()).sort(compare),
    [selectedAddress]
  );

  return (
    <div className="d-flex justify-content-center">
      <Dropdown title="Select wallet">
        <Dropdown.Toggle
          id="dropdown-toggle"
          as={DropDownToggle}
          address={selectedAddress}
          showMenu={items?.length > 0}
          blockchain={
            user.data?.addresses &&
            user.data?.addresses.find((x) => x.address.toLowerCase() === selectedAddress.toLowerCase())?.blockchain
          }
        />
        {items && (
          <Dropdown.Menu className="w-100">
            {items.map((contract) => (
              <Dropdown.Item key={contract.address} onClick={() => navigate(`/wallet/${contract.address}`)}>
                <WalletElement address={contract.address} blockchain={contract.blockchain} />
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </div>
  );
}

DropDownToggle.defaultProps = {
  children: undefined,
  onClick: undefined,
  blockchain: '',
};
