import React, { useEffect, useState } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { getEnvList } from '../../common';

export default function SelectEnvComponent(): JSX.Element {
  const [env, setEnv] = useState('');

  const handleEnvSelect = (eventKey: string | null) => {
    localStorage.setItem('env', eventKey || '0');
    setEnv(getEnvList()[Number(eventKey || 0)]);
    window.location.reload();
  };

  useEffect(() => {
    setEnv(getEnvList()[Number(localStorage.getItem('env') || '0')]);
  }, []);

  return (
    <Nav className="d-inline-block" onSelect={handleEnvSelect}>
      <NavDropdown
        title={
          <p className="d-inline-block me-1 sm-label text-capitalize fst-italic">
            {env} <i className="icon-server ms-1" />
          </p>
        }
        align="end"
      >
        {getEnvList()?.map((e, i) => {
          return (
            <NavDropdown.Item className="text-capitalize" eventKey={i}>
              {e}
            </NavDropdown.Item>
          );
        })}
      </NavDropdown>
    </Nav>
  );
}
