import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import axios from 'axios';
import Wallet from '../../models/wallet-model';
import FirstOasisService from '../../services/wallet/first-oasis-service';
import KaikasService from '../../services/wallet/kaikas-service';
import MetaMaskService from '../../services/wallet/meta-mask-service';
import WebXcpService from '../../services/wallet/web-xcp-service';
import Web3Service from '../../services/wallet/web3-service';
import { extractAccount } from '../../helpers/tools';
import WalletDropDown from '../wallet-drop-down/wallet-drop-down-control';
import './wallet-menu-component.scss';
import BlockchainNetworkId from '../../enums/blockchin-network-id';
import { User } from '../../models/user-model';
import { getUserAddressesAsync } from '../../services/api-service';

const initialState: Wallet = {
  address: '',
  isInstalled: false,
  chain: 0,
};

const web3Service: Web3Service = new Web3Service();
const metaMaskService: MetaMaskService = new MetaMaskService();
const kaikasService: KaikasService = new KaikasService();
const webXcpService: WebXcpService = new WebXcpService();
const firstOasisService: FirstOasisService = new FirstOasisService();
const isInsideCasaTookan = !!window.webXCP;

export default function WalletMenu(): JSX.Element {
  const [metamaskWallet, setMetamaskWallet] = useState<Wallet>({
    ...initialState,
    chain: BlockchainNetworkId.Ethereum,
  });
  const [kaikasWallet, setKaikasWallet] = useState<Wallet>({
    ...initialState,
    chain: BlockchainNetworkId.Klaytn,
  });
  const [web3Wallet, setWeb3Wallet] = useState<Wallet>({
    ...initialState,
    chain: BlockchainNetworkId.Ethereum,
  });
  const [webXcpWallet, setWebXcpWallet] = useState<Wallet>({
    ...initialState,
    chain: BlockchainNetworkId.Counterparty,
  });
  const [firstOasisWallet, setFirstOasisWallet] = useState<Wallet>({
    ...initialState,
    chain: BlockchainNetworkId.FirstOasisKlaytn,
  });

  function metamaskChainChange(chainId: string | number) {
    metaMaskService.logInfo(`metamaskChainChange: ${chainId}`);

    setMetamaskWallet((prevState) => ({ ...prevState, chain: chainId, address: '' }));
  }

  function metamaskNetworkChange(networkId: string | number) {
    metaMaskService.logInfo(`metamaskNetworkChange: ${networkId}`);

    setMetamaskWallet((prevState) => ({ ...prevState, address: '' }));
  }

  function metamaskAccountChange(newAccounts: string | string[]) {
    metaMaskService.logInfo(`metamaskAccountChange: ${newAccounts}`);

    const account = extractAccount(newAccounts);
    if (account) {
      setMetamaskWallet((prevState) => ({ ...prevState, address: account }));
    } else {
      setMetamaskWallet((prevState) => ({ ...prevState, address: '' }));
    }
  }

  function metamaskDisconnect() {
    setMetamaskWallet((prevState) => ({ ...prevState, address: '' }));
  }

  function kaikasChainChange(chainId: string | number) {
    kaikasService.logInfo(`kaikasChainChange: ${chainId}`);

    setKaikasWallet((prevState) => ({ ...prevState, chain: chainId, address: '' }));
  }

  function kaikasNetworkChange(networkId: string | number) {
    kaikasService.logInfo(`kaikasNetworkChange: ${networkId}`);

    setKaikasWallet((prevState) => ({ ...prevState, address: '' }));
  }

  function kaikasAccountChange(newAccounts: string | string[]) {
    kaikasService.logInfo(`kaikasAccountChange: ${newAccounts}`);

    // hack for not catching the kaikas event sent not from our services
    // we have to assign wallet first and then change the address
    const wallet = kaikasWallet;
    if (!Array.isArray(newAccounts) || (Array.isArray(newAccounts) && wallet.address)) {
      wallet.address = extractAccount(newAccounts);
      setKaikasWallet((prevState) => ({ ...prevState, address: wallet.address }));
    }
  }

  function kaikasDisconnect() {
    setKaikasWallet((prevState) => ({ ...prevState, address: '' }));
  }

  function web3AccountChange(newAccounts: string | string[]) {
    web3Service.logInfo(`web3AccountChange: ${newAccounts}`);

    if (newAccounts) {
      const account = extractAccount(newAccounts);
      setWeb3Wallet((prevState) => ({ ...prevState, address: account, isInstalled: true }));
    } else {
      setWeb3Wallet((prevState) => ({ ...prevState, address: '', isInstalled: false }));
    }
  }

  function web3Disconnect() {
    setWeb3Wallet((prevState) => ({ ...prevState, address: '' }));
  }

  function webXcpAccountChange(newAccounts: string | string[]) {
    webXcpService.logInfo(`webXcpAccountChange: ${newAccounts}`);

    setWebXcpWallet((prevState) => ({ ...prevState, address: newAccounts }));
  }

  function firstOasisAccountChange(newAccounts: string | string[]) {
    firstOasisService.logInfo(`firstOasisAccountChange: ${newAccounts}`);
    if (!newAccounts) {
      setFirstOasisWallet((prevState) => ({ ...prevState, address: '', isInstalled: false }));
    }

    setFirstOasisWallet((prevState) => ({ ...prevState, address: newAccounts, isInstalled: true }));
  }

  // Web3
  useEffect(() => {
    async function init() {
      await web3Service.init(undefined, undefined, web3AccountChange, web3Disconnect);

      const isWeb3Installed = web3Service.isWalletInstalled();
      if (isWeb3Installed) {
        setWeb3Wallet((prevState) => ({
          ...prevState,
          chain: 1,
          networkId: 1,
          isInstalled: isWeb3Installed,
        }));
      }

      if (isInsideCasaTookan) {
        await web3Service.connect();
      }
    }

    init();
  }, []);

  // MetaMask
  useEffect(() => {
    async function init() {
      await metaMaskService.init(metamaskChainChange, metamaskNetworkChange, metamaskAccountChange, metamaskDisconnect);

      const isMetaMaskInstalled = metaMaskService.isWalletInstalled();
      if (isMetaMaskInstalled) {
        setMetamaskWallet((prevState) => ({
          ...prevState,
          isInstalled: isMetaMaskInstalled,
        }));
      }
    }

    init();
  }, []);

  // Kaikas
  useEffect(() => {
    async function init() {
      await kaikasService.init(kaikasChainChange, kaikasNetworkChange, kaikasAccountChange, kaikasDisconnect);

      const isWalletInstalled = kaikasService.isWalletInstalled();
      if (isWalletInstalled) {
        setKaikasWallet((prevState) => ({
          ...prevState,
          isInstalled: isWalletInstalled,
        }));
      }
    }

    init();
  }, []);

  // WebXcp
  useEffect(() => {
    async function init() {
      await webXcpService.init(undefined, undefined, webXcpAccountChange);

      const isWebXcpInstalled = webXcpService.isWalletInstalled();
      if (isWebXcpInstalled) {
        setWebXcpWallet((prevState) => ({
          ...prevState,
          isInstalled: isWebXcpInstalled,
        }));
      }

      if (isInsideCasaTookan) {
        await webXcpService.connect();
      }
    }

    init();
  }, []);

  // FirstOasis
  useEffect(() => {
    firstOasisService.init(undefined, undefined, firstOasisAccountChange);
    const cancelToken = axios.CancelToken.source();
    const ethAddress = metamaskWallet?.address || web3Wallet?.address;
    if (ethAddress) {
      getUserAddressesAsync(extractAccount(ethAddress), cancelToken).then((res) => {
        const user: User = res;
        if (user) {
          const foAddressUser = user?.data?.addresses?.find((x) => x.blockchain === 'firstOasisKlaytn');
          const address = foAddressUser ? foAddressUser?.address : '';
          firstOasisService.connect(address);
        }
      });
    }

    return () => {
      cancelToken.cancel();
    };
  }, [metamaskWallet, web3Wallet]);

  return (
    <Dropdown className="d-inline wallet-dropdown-menu">
      <Dropdown.Toggle>
        {/* No firstOasis wallet because it connects together with the ETH wallet */}
        <span>
          {web3Wallet.address || metamaskWallet.address || kaikasWallet.address || webXcpWallet.address
            ? 'My Wallet'
            : 'Connect Wallet'}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
        {metamaskWallet && metamaskWallet.isInstalled && !isInsideCasaTookan ? (
          <WalletDropDown wallet={metamaskWallet} walletService={metaMaskService} />
        ) : (
          <WalletDropDown wallet={web3Wallet} walletService={web3Service} />
        )}
        {!isInsideCasaTookan && (
          <WalletDropDown
            wallet={kaikasWallet}
            walletService={kaikasService}
            key={extractAccount(kaikasWallet.address)}
          />
        )}
        {isInsideCasaTookan && firstOasisWallet.address && (
          <WalletDropDown wallet={firstOasisWallet} walletService={firstOasisService} />
        )}
        {isInsideCasaTookan && <WalletDropDown wallet={webXcpWallet} walletService={webXcpService} />}
      </Dropdown.Menu>
    </Dropdown>
  );
}
