import Blockchain from '../enums/blockchain-enum';
import { Transaction } from '../models/transaction-model';
import Sorting from '../enums/sorting-enum';

function shortenText(text: string, numberOfChars?: number): string {
  let chars = numberOfChars ?? 8;
  chars = chars < 1 ? 1 : chars;
  if (text && text.length >= chars * 2 + 3) {
    const result = `${text.substring(0, chars)}...${text.substring(text.length - chars)}`;
    return result;
  }

  return text;
}

function getNetworkName(networkId?: string | number): string {
  switch (networkId) {
    case 1: {
      return Blockchain.Ethereum;
    }
    case 8217: {
      return Blockchain.Klaytn;
    }
    case -1: {
      return Blockchain.Counterparty;
    }
    case -2: {
      return Blockchain.FirstOasisKlaytn;
    }
    default: {
      return 'Not supported';
    }
  }
}

function getNetworkLongName(networkId?: string | number): string {
  switch (networkId) {
    case -2: {
      return 'First Oasis';
    }
    case -1:
    case 1:
    case 8217:
    default: {
      return getNetworkName(networkId);
    }
  }
}

function extractAccount(accounts: string | string[]): string {
  return Array.isArray(accounts) ? accounts[0] : accounts;
}

function capitalizeFirstLetter(text: unknown): string {
  const str = `${text}`;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function convertDate(seconds: string): string {
  const date: Date = new Date(0);
  date.setUTCSeconds(Number.parseInt(seconds, 10));
  return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(
    -2
  )} ${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(-2)}`;
}

function removeDuplicates(items: string[]): string[] {
  return items.filter((v, i, a) => a.indexOf(v) === i && v);
}

function validateEmail(email: string): boolean {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function scrollToTop(): void {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
}

function extractContractOrName(transactionItem: Transaction): string {
  const contractAddress = transactionItem?.contract?.address || '';
  if (contractAddress.replace(/\D/g, '').length > 0) {
    const links = transactionItem?.orbs?.map((x) => x?.imgURL || x?.imageUrl || x?.asset?.imgURL);
    if (links && links?.[0] && links?.[0].length > 0) {
      const link = links?.[0];
      const segments = link.split(/[/.]/g);
      if (segments && segments.length > 1) {
        const name = segments[segments.length - 2];
        if (name.replace(/\D/g, '').length === 0) {
          return name.toUpperCase();
        }
      }
    }
  }

  return contractAddress;
}

function deepCloneObject<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}

function sortArray<T>(array: T[], fieldName: string, sorting: Sorting): T[] {
  if (sorting === Sorting.None) {
    return array;
  }

  const indicator = sorting === Sorting.Ascending ? 1 : -1;
  return array.sort((a, b) =>
    (a[fieldName as keyof T] ?? '') > (b[fieldName as keyof T] ?? '') ? indicator : -1 * indicator
  );
}

function generateRandomString(): string {
  return (Math.random() + 1).toString(36).substring(7);
}

function replaceIPFSGateway(src: string): string {
  if (src) {
    if (src.indexOf('ipfs.nftstorage.link') !== -1) {
      const newSrc = src.replace('https://', '').replace('.ipfs.nftstorage.link/', '');
      return `https://ipfsc.crystalsuite.com/${newSrc}`;
    }
  }

  return src;
}

export {
  shortenText,
  getNetworkName,
  getNetworkLongName,
  extractAccount,
  capitalizeFirstLetter,
  convertDate,
  removeDuplicates,
  validateEmail,
  scrollToTop,
  extractContractOrName,
  deepCloneObject,
  sortArray,
  generateRandomString,
  replaceIPFSGateway,
};
