import axios from 'axios';
import { getAPIRoute } from './common';

const apiRoute = getAPIRoute();
const metadataRoute = process.env.REACT_APP_METADATA_ROUTE;
const tobleroneRoute = process.env.REACT_APP_TOBLERONE_ROUTE;
const newsletterRoute = process.env.REACT_APP_NEWSLETTER_ROUTE;

export const COLLECTIONS_URL = 'collections';
export const TRANSACTIONS_URL = 'events{address}';
export const COLLECTION_DETAILS_URL = 'collections/';
export const TRANSACTION_FOR_COLLECTION_URL = 'events{address}?collection=';
export const USER_ADDRESSES = 'user/get/';
export const WALLET_BALANCE = 'balances/';
export const USER_GAMES = 'user{address}/app/';
export const CAROUSELS = 'collections/carousels';
export const WITHDRAW_VERIFY_URL = 'firstOasis/withdrawVerify';
export const WITHDRAW_URL = 'firstOasis/withdraw';
export const DEPOSIT_URL = 'bearwhale/api/firstOasis/depositExecutor/user/';
export const TRANSACTION_URL = 'event';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const balorApi = axios.create({
  baseURL: `${apiRoute}api/v1/`,
  headers,
});

export const tobleroneApi = axios.create({
  baseURL: `${tobleroneRoute}`,
  headers,
});

export const spellsOfGenesisApi = axios.create({
  baseURL: `${metadataRoute}cards`,
  headers,
});

export const newsletterApi = axios.create({
  baseURL: `${newsletterRoute}cards`,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});
